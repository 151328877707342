import logger from 'saddlebag-logger';

import { loadExternalScripts } from './scriptLoader';

if (window.performance && window.performance.mark) {
  window.performance.mark('footer_flags_start');
}

const component = 'footer-flags';
const IDLE_TIMEOUT = 5000;

const main = async () => {
  const logError = (message) =>
    logger.logError({ message, level: 'error', component });

  if (process.env.NODE_ENV === 'production') {
    try {
      await loadExternalScripts();
    } catch (missing) {
      logError(`Failed to load dependencies ${missing}`);
      return;
    }
  }

  try {
    const React = await import(/* webpackMode: "eager" */ 'react');
    const ReactDOM = await import(/* webpackMode: "eager" */ 'react-dom');
    const { default: FooterFlags } = await import(
      /* webpackMode: "eager" */ './components/FooterFlags'
    );

    const { props } = window[component] || {};
    props.logger = logger;
    const container = document.getElementById(`${component}-root`);

    ReactDOM.hydrate(React.createElement(FooterFlags, props), container);

    if (window.performance && window.performance.mark) {
      window.performance.mark('footer_flags_ready');
    }
  } catch (err) {
    if (process.env.NODE_ENV === 'production') {
      logError(`Failed to render component: ${err}`);
    } else {
      throw err;
    }
  }
};

const { delayHydration } = window[component] || {};

if ('requestIdleCallback' in window && delayHydration) {
  requestIdleCallback(main, { timeout: IDLE_TIMEOUT });
} else {
  main();
}
